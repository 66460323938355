import React from 'react';
import { connect } from 'react-redux';

import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import './css/Acknowledgement.scss';
import protocolNames from 'app/utils/protocolNames';
import { useAppSelector } from 'app/helpers/hooks';
import { KingV2SubProducts, AvailableProducts } from 'app/constants/Products';

const mapStateToProps = (_) => {
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestOnboardingStepPath({ customer: stateAfterSuccess }));

  return {
    onSuccessActionCreator,
  };
};

const benefits = ['Increased energy', 'More lean mass', 'Greater motivation', 'More confidence'];
const access = [
  'Prescription medication capsules (if qualified)',
  'Licensed doctor consultations',
  'Live coaching & community',
  'Free 2 day priority shipping for meds',
];

const productDetails = {
  [KingV2SubProducts.EncloPregnolone]: {
    title: 'EP',
    path: '/enclomiphene',
  },
  [KingV2SubProducts.Trt]: {
    title: 'Oral TRT',
    desc: "The Oral TRT protocol delivers native testosterone enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver's first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.",
    path: '/oral-trt',
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    title: 'Oral TRT+',
    desc: "The Oral TRT+ protocol delivers a unique release of testosterone designed to improve your energy and body composition without impacting your fertility markers, liver, kidney, or testicular function. It's effective - with none of the painful injections, messy creams, or side effects associated with other testosterone treatments.",
    path: '/testosterone-replacement-therapy',
  },
};

const Acknowledgement = ({ onSuccessActionCreator, step, dispatch }) => {
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, AvailableProducts.King));
  const kingSubProductName = product?.get('opt_in_choice')?.get('selected_king_v2_product');
  const protocolName = productDetails[kingSubProductName]?.title || protocolNames[AvailableProducts.King];

  const onClick = () => {
    const cmdType = 'answer_questions';
    const params = { [step]: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <div className="p16 king-intro">
      <h1>
        The {productDetails[kingSubProductName]?.title || <span>{protocolNames[AvailableProducts.King]}</span>} Protocol
      </h1>
      <P className="mt16">
        {productDetails[kingSubProductName]?.desc ||
          `The ${protocolName} Protocol uses a convenient, prescription tablet that stimulates your body’s own natural testosterone production. It’s effective – with none of the painful injections, messy creams, or side effects associated with other testosterone treatments.`}
      </P>
      <P className="bold mt20">Benefits include:</P>
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <P className="bold mt36">You will have access to:</P>
      <ul>
        {access.map((a) => (
          <li key={a}>
            <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
    </div>
  );
};

export default connect(mapStateToProps)(Acknowledgement);
